import React, { useContext, Fragment, useState, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { Box, Heading, withWindowSize } from "@thecb/components";
import { ScreendoorFormContent } from "./ScreendoorForm.styled.js";
import { Helmet } from "react-helmet";
import { FONT_WEIGHT_REGULAR } from "../../../../constants/style_constants.js";
import ScriptTag from "react-script-tag";

const injectRendererScript = projectId => {
  const script = document.createElement("script");
  script.innerHTML = `
       // Uncomment this line and set it to the CSS class that your website uses for buttons:
       FormRenderer.BUTTON_CLASS = 'btn themePrimaryButton';

       // Uncomment this line if you want respondents to be able to access a permalink to their draft:
       // FormRenderer.addPlugin('BookmarkDraft');

       new FormRenderer({"project_id":"${projectId}"});
     `;
  document.body.appendChild(script);
};

const ScreendoorBlock = ({ title, screendoorProjectId }) => {
  const themeContext = useContext(ThemeContext);
  const [jQueryLoaded, setJQueryLoaded] = useState(!!window.jQuery);
  const [formRendererLoaded, setFormRendererLoaded] = useState(
    !!window.FormRenderer
  );
  const { isMobile } = themeContext;

  useEffect(() => {
    if (jQueryLoaded && formRendererLoaded) {
      injectRendererScript(screendoorProjectId);
    }
  }, [jQueryLoaded, formRendererLoaded]);

  return (
    <Fragment>
      <Helmet>
        <link
          href="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        />
      </Helmet>
      {!jQueryLoaded && (
        <ScriptTag
          type="text/javascript"
          nonce="91976fdb5b1f0261652da4d5a8d09138"
          src="//code.jquery.com/jquery-2.2.3.min.js"
          onLoad={() => setJQueryLoaded(true)}
        />
      )}
      {jQueryLoaded && !formRendererLoaded && (
        <ScriptTag
          type="text/javascript"
          nonce="91976fdb5b1f0261652da4d5a8d09138"
          src="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.js"
          onLoad={() => setFormRendererLoaded(true)}
        />
      )}

      <Box padding={isMobile ? "24px 0 0 0" : "40px 0 0 0"} background="white">
        <Heading variant="h4" weight={FONT_WEIGHT_REGULAR}>
          {title}
        </Heading>
        <Box padding="0">
          <ScreendoorFormContent data-formrenderer></ScreendoorFormContent>
        </Box>
      </Box>
    </Fragment>
  );
};

export default withWindowSize(ScreendoorBlock);
